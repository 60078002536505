import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import CartierCategoryBtns from '../../components/preowned/CartierCategoryBtns'

// markup
const CartierTank = () => {
  const data = useStaticQuery(
    graphql`
      query queryCartierTank {
        products: allStrapiProduct(
          filter: { brand: { eq: "Cartier" }, model: { regex: "/Tank/" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Cartier Tank Watches for Sale'}
      canonical={'/fine-watches/cartier/cartier-tank-francaise/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Cartier Tank Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/cartier/filter/model/americaine-tank,tank,tank-americaine,tank-anglaise,tank-anglaise-xl,tank-francaise,tank-louis,tank-solo/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-cartier-tank-header.png"
                alt="Pre-Owned Certified Used Cartier Tank Header"
                aria-label="Used Cartier Tank Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">USED CARTIER TANK</h1>
            <h2>
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE AND MODERN CARTIER TANK
              WATCHES AT GRAY AND SONS
            </h2>
            <h3>
              BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED CARTIER TANK WATCHES WITH GRAY AND
              SONS JEWELERS
            </h3>
            <p>
              We are the best real watch and jewelry store located in Surfside, Miami Beach,
              Florida. We're across from Bal Harbour Shops. Gray and Sons is known for our in
              person, real watch and jewelry experts on-site, open six days a week, and that we're
              real, not a virtual store. Our customers say we are the #1 Miami used watch buyers and
              sellers who've proudly assisted customers for the past 42 years. We're sellers and
              buyers of pre-owned Cartier Tank watches in Miami, Miami Beach, Coral Gables, Coconut
              Grove, South Beach, Bal Harbour, Surfside, Sunny Isles , Aventura, Fort Lauderdale and
              many more areas.
              <br />
              <br />
              Gray and Sons Jewelers sell certified pre-owned Cartier Watches. Gray and
              Sons and their buying division &ensp;
              <a href="https://sellusyourjewelry.com">sellusyourjewelry.com</a> will also buy your
              used Cartier watches for cash. Come to Gray and Sons for all your watch needs,
              including battery replacement. We are a safe place to buy, sell or trade your Cartier
              watch. We have in-stock inventory or Cartier, Rolex, Patek Philippe, Audemars Piguet,
              and more. Talk with a real decision maker to strike a great deal today. We offer
              certified pre-owned watches.
              <br />
              <br />
              Royalty and prestige are the main features associated with Cartier. The luxury jewelry
              and watch brand, which for many years now has 100% brand recognition. The technical
              mastery, innovation, and timeless design grant Cartier a special place within the
              luxury jewelry and wristwatch market, which also means that the prices increase over
              time. For that reason, many people often decide to own one of the prestigious Cartier
              watches not only as a luxurious accessory but also as an investment.
              <br />
              <br />
              Are you thinking of buying a Cartier watch? Luckily for everyone it is possible to
              save some money and time. Waiting lists, unavailable models, etc. are no longer an
              issue for those who buy at Gray &amp; Sons. Every Cartier wristwatch purchased at Gray
              &amp; Sons is certified pre-owned and comes backed with a 1-year Gray &amp; Sons
              Jewelers Warranty. The most popular and sought-after Cartier models are Cartier Ballon
              Bleu, Cartier Pasha, Cartier Santos, Cartier Tank, Cartier Tortue, Cartier Maillon,
              and Cartier Panthere. You can also find Cartier watches by their reference numbers
              among others: Ref. 09601, Ref. 1035, Ref. 1050, Ref. 1440, Ref. 1565, Ref. 16203, Ref.
              075/150, Ref. 1-018, Ref. 2377, Ref. 1120_c
              <br />
              <br />
              For over 40 years, Gray &amp; Sons has been specializing in selling luxury jewelry
              &amp; certified watches, including pre-owned Cartier watches, such as Cartier Ballon
              Bleu, Cartier Pasha, Cartier Santos, and Cartier Tank. The vast inventory allows Gray
              &amp; Sons to offer several Cartier models in yellow gold, white gold, and stainless
              steel, as well as diamond Cartier watches with diamond bezel. The company also
              specializes in professional luxury watch repair, restoration, polishing, and cleaning
              for fine Swiss watches, including Cartier watches, Rolex, Omega, Patek Philippe, and
              more.
              <br />
              <br />
              For more details, visit our showroom in Surfside, Miami, or check our website{' '}
              <a href="/fine-watches/cartier/cartier-tank-francaise/">www.grayandsons.com</a>
              . Our company is proud to have qualified and trusted in-house watchmakers and jewelers
              who stand ready to assist our customers. Make the best choice and buy your dream
              certified pre-owned Cartier watch for the best price. The finest selection of used
              Cartier watches - only at Gray &amp; Sons. Our customers call us the #1 pre-owned
              Cartier Watch company. Great Cartier Watches offered for sale from our inventory
              include Cartier Tortue "Tourbillon Platinum" 39mm Ref. W1545751. This watch is made
              out of Platinum on a Black Alligator strap with a Platinum Deploy buckle. This Cartier
              watch has a 39 x 37 mm case. Another fine Cartier selection in our inventory is the
              Cartier Tank Anglaise XL 36.5mm Ref. W5310018. This watch is made out of yellow gold
              on an 18k bracelet with an 18k Deploy buckle. This Cartier watch has a 36.5 x 35 mm
              case with a Rectangle caseback and Silver Roman Numeral dial. Our watches have been
              recently serviced, freshly lubricated, timing adjusted and detailed and come with our
              exclusive in house one year warranty, and two years warranty for modern Rolex watches.
              <br />
              <br />
              We are so excited to hear from you and have you sign up for our free monthly catalog.
              Visit the store, call or visit our website at <a href="/">www.grayandsons.com</a>.
              Lot’s of people are buying used and pre-owned Cartier Watches, and many people are
              looking for a place to sell their used Cartier Watches. Gray and Sons will do both. If
              you’re one of the people saying, “I want to sell my Cartier wrist watch for cash”
              visit <a href="/">www.grayandsons.com</a> and &ensp;
              <a href="https://sellusyourjewelry.com">sellusyourjewelry.com</a> and we will buy your
              pre-owned Cartier Watch for cash. Reach out to one of our real watch and jewelry
              specialist today. Rich and Vika are <a href="/chat-with-rich">HERE</a> to answer any
              and all of your questions.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/cartier/">
                <button>SHOP CARTIER WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK CARTIER WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <CartierCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default CartierTank
